<script>
  import Vue from 'vue'
  import {api} from './api'
  import axios from 'axios'
  import VueRouter from 'vue-router'
  import moment from 'moment-timezone'
  import DisableDevtool from 'disable-devtool';

  import Home from '@/components/Home'
  import Web from '@/components/new/Web';
  import Dashboard from '@/components/Dashboard'
  import Events from '@/components/Events'
  import EventsResults from '@/components/EventsResults'
  import BetsDash from '@/components/Bets'
  import Leagues from '@/components/Leagues'
  import User from '@/components/User'
  import Users from '@/components/Users'
  import Odds from '@/components/Odds'
  import Configs from '@/components/Configs'
  import CashGeneral from '@/components/CashGeneral'
  import CashClients from '@/components/CashClients'
  import CashManager from '@/components/CashManager'
  import CashSalesman from '@/components/CashSalesman'
  import CashSupervisor from '@/components/CashSupervisor'
  import CashSalesmanByManager from '@/components/CashSalesmanByManager'
  import Releases from '@/components/Releases'
  import RulesLeagues from '@/components/RulesLeagues'
  import RulesSalesman from '@/components/RulesSalesman'
  import RulesManagers from '@/components/RulesManagers'
  import RulesClients from '@/components/RulesClients'
  import RulesSupervisor from '@/components/RulesSupervisor'
  import Challenge from '@/components/Challenge'
  import Ticket from '@/components/Ticket'
  import Register from '@/components/Register'
  import LiveTicket from '@/components/LiveTicket'
  import Transactions from '@/components/Transactions'
  import CommissionStandard  from '@/components/CommissionStandard'
  import LiveEvents from '@/components/EventsLive'
  import Logs from '@/components/Logs'
  import Affiliate from '@/components/Affiliate'
  import Validate from '@/components/Validate'
  import Login from '@/components/Login'
  import Bets from '@/components/new/Bets'
  import Table from '@/components/new/Table'
  import Finance from '@/components/new/Finance'
  import Casino from '@/components/new/Casino'
  import MyAccount from '@/components/new/MyAccount'
  import Store from '@/components/new/Store'
  import StoreDash from '@/components/Store'
  import CasinoDash from '@/components/Casino'
  import Payment from '@/components/Payment'
  import Sorteio from '@/components/new/Sort'
  import Help from '@/components/Help'

  Vue.use(VueRouter)

  const pageTitle = document.title

  const router = new VueRouter({
      mode: 'history',
      base: __dirname,
      routes: [
        { 
          path: '*', 
          redirect: '/',
          meta: {
            title: pageTitle
          }
        },
        {
          path: '/',
          component: Web,
          meta: {
            title: pageTitle
          }
        },
        {
          path: '/store',
          component: Store,
          meta: {
            title: `${pageTitle} - Loja`
          }
        },
        {
          path: '/login',
          component: Login, 
          meta: {
            title: `${pageTitle} - Login`
          }
        },
        {
          path: '/cassino',
          component: Casino,
          meta: {
            title: `${pageTitle} - Cassino`
          }
        },
        {
          path: '/account',
          component: MyAccount,
          meta: {
            title: `${pageTitle} - Minha conta`
          }
        },
        {
          path: '/register',
          component: Register,
          meta: {
            title: `${pageTitle} - Cadastro`
          }
        },
        {
          path: '/bets',
          component: Bets,
          meta: {
            title: `${pageTitle} - Apostas`
          }
        },
        {
          path: '/table',
          component: Table,
          meta: {
            title: `${pageTitle} - Tabela`
          }
        },
        {
          path: '/finance',
          component: Finance,
          meta: {
            title: `${pageTitle} - Caixa`
          }
        },
        {
          path: '/ticket/:code',
          component: Ticket,
          meta: {
            title: `${pageTitle} - Bilhete virtual`
          }
        },
        {
          path: '/streaming/:code',
          component: LiveTicket,
          meta: {
            title: `${pageTitle} - Acompanhar aposta ao vivo`
          }
        },
        {
          path: '/affiliate/:code',
          component: Affiliate,
          meta: {
            title: `${pageTitle} - Afiliado`
          }
        },
        {
          path: '/validate/:code',
          component: Validate,
          meta: {
            title: `${pageTitle} - Validação`
          }
        },
        { 
          path: '/dashboard', 
          component: Dashboard,
          meta: {
            title: `${pageTitle} - Dashboard`
          }
        },
        {
          path: '/dashboard/events',
          component: Events,
          meta: {
            title: `${pageTitle} - Eventos`
          }
        },
        {
          path: '/dashboard/results',
          component: EventsResults,
          meta: {
            title: `${pageTitle} - Resultados`
          }
        },
        {
          path: '/dashboard/bets',
          component: BetsDash,
          meta: {
            title: `${pageTitle} - Apostas`
          }
        }, 
        {
          path: '/dashboard/leagues',
          component: Leagues,
          meta: {
            title: `${pageTitle} - Ligas`
          }
        },
        {
          path: '/dashboard/user',
          component: User,
          meta: {
            title: `${pageTitle} - Novo usuário`
          }
        },
        {
          path: '/dashboard/users',
          component: Users,
          meta: {
            title: `${pageTitle} - Lista de usuários`
          }
        },
        {
          path: '/dashboard/odds',
          component: Odds,
          meta: {
            title: `${pageTitle} - Alterar odds`
          }
        },
        {
          path: '/dashboard/cash/general',
          component: CashGeneral,
          meta: {
            title: `${pageTitle} - Caixa geral`
          }
        },
        {
          path: '/dashboard/cash/manager',
          component: CashManager,
          meta: {
            title: `${pageTitle} - Caixa dos gerentes`
          }
        },
        {
          path: '/dashboard/cash/salesman',
          component: CashSalesman,
          meta: {
            title: `${pageTitle} - Caixa dos vendedores`
          }
        },
        {
          path: '/dashboard/cash/clients',
          component: CashClients,
          meta: {
            title: `${pageTitle} - Caixa dos clientes`
          }
        },
        {
          path: '/dashboard/cash/supervisor',
          component: CashSupervisor,
          meta: {
            title: `${pageTitle} - Caixa dos supervisores`
          }
        },
        {
          path: '/dashboard/cash/salesman-by-manager',
          component: CashSalesmanByManager,
          meta: {
            title: `${pageTitle} - Caixa do vendedor por gerente`
          }
        },
        {
          path: '/dashboard/rules-leagues',
          component: RulesLeagues,
          meta: {
            title: `${pageTitle} - Odds por ligas`
          }
        },
        {
          path: '/dashboard/rules-managers',
          component: RulesManagers,
          meta: {
            title: `${pageTitle} - Odds por gerentes`
          }
        },
        {
          path: '/dashboard/rules-salesman',
          component: RulesSalesman,
          meta: {
            title: `${pageTitle} - Odds por vendedores`
          }
        },
        {
          path: '/dashboard/rules-clients',
          component: RulesClients,
          meta: {
            title: `${pageTitle} - Odds por clientes`
          }
        },
        {
          path: '/dashboard/rules-supervisor',
          component: RulesSupervisor,
          meta: {
            title: `${pageTitle} - Odds por supervisores`
          }
        },
        {
          path: '/dashboard/challenge',
          component: Challenge,
          meta: {
            title: `${pageTitle} - Desafios`
          }
        },
        {
          path: '/dashboard/releases',
          component: Releases,
          meta: {
            title: `${pageTitle} - Lançamentos`
          }
        },
        {
          path: '/dashboard/configs',
          component: Configs,
          meta: {
            title: `${pageTitle} - Configurações`
          }
        },
        {
          path: '/dashboard/transactions',
          component: Transactions,
          meta: {
            title: `${pageTitle} - Transações`
          }
        },
        {
          path: '/dashboard/commission-standard',
          component: CommissionStandard,
          meta: {
            title: `${pageTitle} - Padrão de comissões`
          }
        }, 
        {
          path: '/dashboard/live-events',
          component: LiveEvents,
          meta: {
            title: `${pageTitle} - Eventos ao vivo`
          }
        }, 
        {
          path: '/dashboard/logs',
          component: Logs,
          meta: {
            title: `${pageTitle} - Logs do sistema`
          }
        },
        {
          path: '/dashboard/store',
          component: StoreDash,
          meta: {
            title: `${pageTitle} - Store`
          }
        },
        {
          path: '/dashboard/casino',
          component: CasinoDash,
          meta: {
            title: `${pageTitle} - Cassino`
          }
        },
        {
          path: '/dashboard/payment',
          component: Payment,
          meta: {
            title: `${pageTitle} - Pagamento`
          }
        },
        {
          path: '/dashboard/sweepstakes',
          component: Sorteio,
          meta: {
            title: `${pageTitle} - Sorteios`
          }
        },
        {
          path: '/dashboard/help',
          component: Help,
          meta: {
            title: `${pageTitle} - Ajuda`
          }
        }
      ]
  })

  let globalData = new Vue({
      data() {
        return {
          home: 1,
          view: 1,
          loja: 'nao',
          cassino: 'nao',
          user_id: 0,
          cadastro: 2,
          app_version: '6.6',
          pipe: false,
          regras: false,
          prebet: false,
          ticket: false,
          sidebar: false,
          loginModal: false,
          isLivePageActive: false,
          pedidos_de_saques: 0,
          current_bonus: 0,
          current_credit: 0,
          current_credit_simple: 0,
          network_erros: {
            0: {
              message: 'Não há conexão com a internet!'
            },
            400: {
              message: 'Não foi possível concluir a solicitação, tente novamente!'
            },
            401: {
              message: 'Você não tem autorização para realizar essa operação!'
            },
            404: {
              message: 'Página solicitada não foi encontrada!'
            },
            500: {
              message: 'O servidor está passando por problemas técnicos!'
            },
            408: {
              message: 'Por favor, verifique sua conexão de rede e tente novamente!'
            }					
          },
          geolocation: {
            ip: '',
            pais: '',
            cidade: '',
            estado: '',
            latitude: '',
            longitude: '',
            provedor: ''
          },
          inGame: false,
          theme: 'default',
          logo: '/images/logo.png',
          image_server: 'https://static.betsnow.net/images',
          isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent),
          service_link: `https://api.betsnow.net/attendance/${window.location.hostname}`,
          lds_ring: `<div class="lds-ring"><div></div><div></div><div></div><div></div></div>`,
          footer: '<div>PROIBIDO PARA MENORES DE 18 ANOS</div><div>Jogue com responsabilidade.</div>',
          isCordova: (window.localStorage.getItem('cordova') || window.location.search.includes('cordova')) ? true : false,
          ue: (window.localStorage.getItem('cordova') || window.location.search.includes('cordova') || /iPhone|iPad|iPod|Android|Windows/i.test(navigator.userAgent)) ? true : false
        }
      }
  });

  Vue.mixin({
    computed: {
      logo: {
        get() {
          return globalData.$data.logo;
        }
      },
      isIOS: {
        get() {
          return globalData.$data.isIOS;
        }
      },
      inGame: {
        get() {
          return globalData.$data.inGame;
        },
        set(value) {
          return globalData.$data.inGame = value;
        }
      },
      app_version: {
        get() {
          return globalData.$data.app_version;
        }
      },
      lds_ring: {
        get() {
          return globalData.$data.lds_ring;
        }
      },
      footer: {
        get() {
          return globalData.$data.footer;
        },
        set(value) {
          return globalData.$data.footer = value;
        }
      },
      network_erros: {
        get() {
          return globalData.$data.network_erros;
        }
      },
      theme: {
        get() {
            return globalData.$data.theme;
        }
      },
      ue: {
        get() {
          return globalData.$data.ue
        }
      },
      isCordova: {
        get() {
          return globalData.$data.isCordova;
        },
        set(value) {
          return globalData.$data.isCordova = value;
        }
      },
      geolocation: {
        get() {
          return globalData.$data.geolocation;
        },
        set(value) {
          return globalData.$data.geolocation = value;
        }        
      },
      loja: {
        get() {
          return globalData.$data.loja;
        },
        set(value) {
          return globalData.$data.loja = value;
        }
      },
      cassino: {
        get() {
          return globalData.$data.cassino;
        },
        set(value) {
          return globalData.$data.cassino = value;
        }
      },
      regras: {
        get() {
          return globalData.$data.regras;
        },
        set(value) {
          return globalData.$data.regras = value;
        }
      },
      service_link: {
        get() {
          return globalData.$data.service_link;
        },
        set(value) {
          return globalData.$data.service_link = value;
        }
      },
      image_server: {
        get() {
          return globalData.$data.image_server;
        }
      },
      current_bonus: {
        get() {
          return globalData.$data.current_bonus;
        },
        set(value) {
          return globalData.$data.current_bonus = value;
        }
      },
      current_credit: {
        get() {
          return globalData.$data.current_credit;
        },
        set(value) {
          return globalData.$data.current_credit = value;
        }
      },
      current_credit_simple: {
        get() {
          return globalData.$data.current_credit_simple;
        },
        set(value) {
          return globalData.$data.current_credit_simple = value;
        }
      },
      sidebar: {
        get() {
          return globalData.$data.sidebar;
        },
        set(value) {
          return globalData.$data.sidebar = value;
        }
      },
      loginModal: {
        get() {
          return globalData.$data.loginModal;
        },
        set(value) {
          return globalData.$data.loginModal = value;
        }
      },
      ticket: {
        get() {
          return globalData.$data.ticket;
        },
        set(value) {
          return globalData.$data.ticket = value;
        }
      },
      home: {
        get() {
          return globalData.$data.home;
        },
        set(value) {
          globalData.$data.home = value;
        }
      },
      view: {
        get() { 
          return globalData.$data.view;
        },
        set(value) { 
          globalData.$data.view = value; 
        }
      },
      user_id: {
        get() {
          return globalData.$data.user_id;
        },
        set(value) {
          globalData.$data.user_id = value;
        }
      },
      cadastro: {
        get() {
          return globalData.$data.cadastro;
        },
        set(value) {
          return globalData.$data.cadastro = value;
        }
      },
      prebet: {
        get() {
          return globalData.$data.prebet;
        },
        set(value) {
          globalData.$data.prebet = value;
        }
      },
      isLivePageActive: {
        get() {
          return globalData.$data.isLivePageActive;
        },
        set(value) {
          globalData.$data.isLivePageActive = value;
        }
      },
      pedidos_de_saques: {
        get() {
          return globalData.$data.pedidos_de_saques;
        },
        set(value) {
          globalData.$data.pedidos_de_saques = value;
        }
      }
    },
    methods: {
      getThemeStyle() {
        let themeTextColor = '#302f2f';
        let themeStyleColor = '#ffc107';
        let themeForeground = '#262626';
        try {
          let style = window.getComputedStyle(document.head);
          themeTextColor = style.getPropertyValue('--ticket-text-color');
          themeForeground = style.getPropertyValue('--ticket-foreground');
          themeStyleColor = style.getPropertyValue('--ticket-style-color');
          return {
            themeStyleColor, 
            themeTextColor, 
            themeForeground
          }
        } catch(e) {
          return {
            themeStyleColor, 
            themeTextColor, 
            themeForeground
          }
        }
      },
      getStyleWidget() {
        let widgetTextColor = '#302f2f';
        let widgetStyleColor = '#ffc107';
        let widgetForeground = '#262626';
        try {
          let style = window.getComputedStyle(document.head);
          widgetTextColor = style.getPropertyValue('--widget-text-color');
          widgetForeground = style.getPropertyValue('--widget-foreground');
          widgetStyleColor = style.getPropertyValue('--widget-style-color');
          return {
            widgetStyleColor, 
            widgetTextColor, 
            widgetForeground
          }
        } catch(e) {
          return {
            widgetStyleColor, 
            widgetTextColor, 
            widgetForeground
          }
        }
      },
      printDesktop(bet, configs) {
        try {

          if (bet instanceof Object) {

            let html = '';
            let anteior_value = 0;
            let total_quotation = 1;
            let hunches = this.orderByScheduleByBet(bet.hunches);
            let commission = (bet.premium / 100) * configs.comissao_aposta_premiada;

            for (let key in hunches) {
                let palpite = hunches[key];
                let league = palpite.sport == '9' ? 'Acumuladão' : palpite.league;

                if (anteior_value != league) {

                    html += '<div class="item no-border bg">';
                    html += '<div class="col text-left text-uppercase">' + league + '</div>';
                    html += '<div class="col text-right text-uppercase">';
                    
                    if (palpite.live) {
                        html += 'Ao vivo';
                    } else if (!palpite.live) {
                        html += 'Pré-jogo';
                    } else if (palpite.sport == '8') {
                        html += 'Desafio';
                    }

                    html += '</div>';
                    html += '</div><!-- /item -->';
                }

                html += '<div class="item no-border">';
                html += '<div class="col text-left text-uppercase">' + palpite.team_home + '</div>';
                html += '<div class="col text-right text-uppercase">' + this.dateTicket(palpite.schedule) + '</div>';
                html += '</div><!-- /item -->';

                html += '<div class="item">';
                html += '<div class="col text-left text-uppercase">' + palpite.team_away + '</div>';
                html += '<div class="col text-right text-uppercase">';

                if (!palpite.accumulated) {
                    html += 'R$ ' + palpite.odd;
                    total_quotation *= palpite.odd;
                }

                html += '</div>';
                html += '</div><!-- /item -->';

                html += '<div class="item no-border">';
                html += '<div class="col text-left text-uppercase">';

                if (palpite.sport == '8') {
                    html += 'Resposta';
                } else {
                    html += 'Palpite';
                }

                html += '</div>';

                html += '<div class="col text-right text-uppercase">';
                html += this.findModality(palpite.sport);
                html += '</div>';

                html += '</div><!-- /item -->';

                if (this.objectFilterBet(bet.hunches, palpite.league, [palpite.team_home, palpite.team_away].join(' x '))) {
                    html += '<div class="item">';
                } else {
                    html += '<div class="item no-border">';
                }

                html += '<div class="text-left text-uppercase">';

                if (palpite.sport == '8') {
                    html += palpite.team_away;
                } else {
                    html += palpite.live ? '(AV) ' + palpite.market : palpite.market;
                }

                html += '</div>';
                html += '</div><!-- /item -->';

                anteior_value = league;
            }

            let template = `
              <html>
                  <head>
                      <style>*{margin:0;padding:0}body{font-size:12px;font-family:"Andale Mono",AndaleMono,monospace}.print-ticket{margin:1em .2em}.print-ticket .site{font-size:24px;font-weight:700;text-align:center;text-transform:uppercase}.print-ticket .site img{filter: brightness(0%)}.print-ticket .content{width:100%}.print-ticket .content .item{display:flex;flex-wrap:wrap;padding:.2em 0;border-bottom:1px solid #000}.print-ticket .content .no-border{padding:0;border-bottom:0}.print-ticket .content .item .col{-ms-flex-preferred-size:0;flex-basis:0;-ms-flex-positive:1;-webkit-box-flex:1;flex-grow:1;max-width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.print-ticket .separator{margin:1em 0;font-size:15;font-weight:700;text-align:center;text-transform:uppercase}.bg{color:#fff;padding:.2em;background-color:#000}.text-left{text-align:left}.text-right{text-align:right}.text-uppercase{text-transform:uppercase}.mensagem{padding:1em 0;text-align:center}.border-top{padding:1em 0;border-top:1px solid #000}</style>
                  </head>
                  <body>
                      <div class="print-ticket">
                          <div class="site">
                              ${configs.nome}
                          </div>
                          <div class="content">
                              <div class="item">
                                  <div class="col text-left text-uppercase">Código</div>
                                  <div class="col text-right text-uppercase">${bet.code}</div>
                              </div><!-- /item -->

                              ${bet.account_type == 'vendedor' ? `
                                  <div class="item">
                                      <div class="col text-left text-uppercase">Vendedor</div>
                                      <div class="col text-right text-uppercase">${bet.salesman}</div>
                                  </div><!-- /item -->
                              ` : ''}

                              <div class="item">
                                  <div class="col text-left text-uppercase">Apostador</div>
                                  <div class="col text-right text-uppercase">${bet.punter}</div>
                              </div><!-- /item -->
                              <div class="item">
                                  <div class="col text-left text-uppercase">Valor apostado</div>
                                  <div class="col text-right text-uppercase">R$ ${this.format_coin(bet.value)}</div>
                              </div><!-- /item -->
                              <div class="item">
                                  <div class="col text-left text-uppercase">Possível retorno</div>
                                  <div class="col text-right text-uppercase">R$ ${this.format_coin(bet.premium)}</div>
                              </div><!-- /item -->
                              <div class="item">
                                  <div class="col text-left text-uppercase">Horário da aposta</div>
                                  <div class="col text-right text-uppercase">${bet.schedule}</div>
                              </div><!-- /item -->
                          </div><!-- /content -->
                          <div class="separator">
                              ${bet.total_hunches == 1 ? 'Meu palpite' : 'Meus palpites'}
                          </div>
                          <div class="content">
                              ${html}
                              <div class="item no-border bg">
                                  <div class="col text-left text-uppercase">Contato</div>
                                  <div class="col text-right text-uppercase">
                                      ${configs.exibir_telefone_vendedor == '1' ? configs.contato : '(00) 0000-0000'}
                                  </div>
                              </div><!-- /item -->
                              ${total_quotation > 1 ? `
                                  <div class="item">
                                      <div class="col text-left text-uppercase">Cotação</div>
                                      <div class="col text-right text-uppercase">${this.format_coin((total_quotation > configs.multiplicador ? configs.multiplicador : total_quotation))}</div>
                                  </div><!-- /item -->
                              ` : ''}
                              ${configs.comissao_aposta_premiada != 0 ? `
                                  <div class="item">
                                      <div class="col text-left text-uppercase">Vendedor paga</div>
                                      <div class="col text-right text-uppercase">R$ ${this.format_coin(bet.premium - commission)}</div>
                                  </div><!-- /item -->                                            
                              ` : ''}
                              <div class="item">
                                  <div class="col text-left text-uppercase">Qtd. palpites</div>
                                  <div class="col text-right text-uppercase">${bet.total_hunches}</div>
                              </div><!-- /item -->
                          </div><!-- /content -->
                          <div class="mensagem">
                              ${configs.comissao_aposta_premiada != 0 ? `
                                  <strong>O vendedor recebera ${configs.comissao_aposta_premiada}% referente a premiação!</strong>
                                  <br />
                                  <br />
                              ` : ''}
                              ${configs.mensagem.replace(/\n/g, "<br />")}
                          </div>
                      </div>
                  </body>
              </html>
            `;

            this.printData(template);
          } else {
            window.alert('Essa aposta não está disponível para a impressão, atualize a página e tente novamente!');                
          }
        } catch(e) {  
          window.alert(e.message);
        }
      },
      dateTicket(date) {
        return moment(date).tz('America/Recife').format('DD/MM - HH:mm');
      },
      orderByScheduleByBet(obj) {
        return Object.keys(obj).map(key => {
            return obj[key];
        }).sort((a, b) => {

            if (this.remove_accents(a.league) < this.remove_accents(b.league)) return -1;
            if (this.remove_accents(a.league) > this.remove_accents(b.league)) return 1;

            if (a.schedule > b.schedule) return 1;
            if (a.schedule < b.schedule) return -1;

            return 0;
        });
      },
      objectFilterBet(obj, league, match) {
        
          let key;
          let filter = {};

          for (key in obj) {
              if (obj[key]['league'] == league) {
                  filter[league] = {
                      match: [obj[key]['team_home'], obj[key]['team_away']].join(' x ')
                  };
              }
          }
      
          if (filter[league].match != match) {
              return true;
          } else {
              return false;
          }
      },
      printDirectApp(via, data, site, configs) {
        window.location.href = 'betsnet://print-bet?data=' + window.btoa(unescape(encodeURIComponent(JSON.stringify({
          via: via,
          data: data,
          site: site,
          configs: configs
        }))));
      },
      shareDirectApp(data, site, configs) {
        window.location.href = 'betsnet://share-image?data=' + window.btoa(unescape(encodeURIComponent(JSON.stringify({
          data: data,
          site: site,
          code: data.code,
          configs: configs,
          theme: this.getThemeStyle()
        }))));
      },
      isMobile() {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop';
      },
      findMarketSituation(palpite) {
        try {
          if (palpite.hasOwnProperty('message')) {
            if (palpite.message) {
              return palpite.message;
            } else {
              return palpite.market;
            }
          } else {
            return palpite.market;
          }
        } catch(e) {
          return palpite.market;
        }
      },
      findModality(modality) {
        if (modality == 1) {
            return 'Futebol';
        } else if (modality == 2) {
            return 'Basquete';
        } else if (modality == 3) {
            return 'Lutas';
        } else if (modality == 4) {
            return 'Hóquei';
        } else if (modality == 5) {
            return 'Voleibol';
        } else if (modality == 6) {
            return 'Tênis';
        } else if (modality == 7) {
            return 'E-Sports';
        } else if (modality == 8) {
            return 'Entretenimento';
        } else {
            return 'Acumuladão';
        }
      },
      findStatus(status) {
        if (status == 0) {
          return 'Aguardando';
        } else if (status == 1) {
          return 'Encerrado';
        } else if (status == 3) {
          return 'Adiado';
        } else {
          return 'Cancelado';
        }
      },
      findSituation(situation) {
        if (situation == 0) {
          return 'Aguardando';
        } else if (situation == 1) {
          return 'Acertou';
        } else if (situation == 2) {
          return 'Perdeu';
        } else if (situation == 3) {
          return 'Adiado';
        } else if (situation == 4) {
          return 'Cancelado';
        } else if (situation == 5) {
          return 'Devolvido';
        } else if (situation == 6) {
          return 'Encerrado';
        } else if (situation == 7) {
          return 'Aposta suspeita';
        } else {
          return 'Sem informações'
        }
      },
      pixType(type) {
        if (type == 'email') {
          return 'E-mail';
        } else if (type == 'cpf_cnpj') {
          return 'CPF/CNPJ';
        } else if (type == 'celular') {
          return 'Celular';
        } else {
          return 'Chave aleatória';
        }
      },
      format_value(nStr) {
          nStr += '';
          let x = nStr.split('.');
          let x1 = x[0];
          let x2 = x.length > 1 ? '.' + x[1] : '';
          let rgx = /(\d+)(\d{3})/;
          while (rgx.test(x1)) {
              x1 = x1.replace(rgx, '$1' + '.' + '$2');
          }
          return x1 + x2;
      },
      format_coin(n, c, d, t, s, i, j) {
          c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
          return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
      },
      remove_accents(str) {
            
        let string = str;
        let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

        for (let key in map) {
              let rex = map[key];
              string = string.replace(rex, key);
        }

        return string;
      },
      googleMap(latitude, longitude) {
        return `<iframe src="https://maps.google.com/maps?q=${latitude},${longitude}&hl=pt-BR;z=10&amp;output=embed" width="100%" height="250" frameborder="0"></iframe>`;
      },
      checkAndroidVersion() {
        try {
          var ua = navigator.userAgent; 
          ua = ua.toLowerCase();

          var and_pos = ua.search("android");
          if(and_pos == -1)
              return 0; //not android

          var pv_pos = ua.indexOf(";", and_pos);
          var versao = ua.slice(and_pos+8, pv_pos);

          return versao;
        } catch(e) {
          return 0;
        }
      },
      printData(html) {

        let print_iframe = document.createElement('iframe');

        print_iframe.width = '0px';
        print_iframe.height = '0px';
        print_iframe.src = 'about:blank';
        print_iframe.style.visibility = 'hidden';

        print_iframe.onload = function() {
            let print_script_tag = print_iframe.contentWindow.document.createElement("script");
                print_script_tag.type = "text/javascript";

            let print_script = print_iframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
                print_script_tag.appendChild(print_script);

            print_iframe.contentWindow.document.body.innerHTML = html;
            print_iframe.contentWindow.document.body.appendChild(print_script_tag);
            print_iframe.contentWindow.Print();
        };
              
        document.body.appendChild(print_iframe);
        print_iframe.remove();
      },
      htmlElement(pathname) {
        const paths = ['/','/bets','/table','/finance','/cassino','/account','/store'];
        const html = document.getElementsByTagName('html');
        if (html) {
          const htmlEl = html[0];
          if (paths.includes(pathname)) {
            if (htmlEl.classList.length == 0) {
                htmlEl.className += 'overflow-hidden';
            } else if (!htmlEl.classList.contains('overflow-hidden')) {
                htmlEl.className += ' overflow-hidden';
            }
          } else if (htmlEl.classList.contains('overflow-hidden')) {
            htmlEl.classList.remove('overflow-hidden');
          }
        }
      },
      getAuthRoken() {
        try {
          return window.localStorage.getItem('x-token') || null;
        } catch(e) {
          return null;
        }
      },
      getBalanceUser() {
        try {
          api.get('balance', {
            timeout: 10000,
            headers: {
              'X-Access-Token': this.getAuthRoken()
            }
          }).then((response) => {
            this.current_bonus = this.format_coin(response.data.bonus);
            this.current_credit = this.format_coin(response.data.credito);
            this.current_credit_simple = this.format_coin(response.data.credito_simples);
          });
        } catch(e) {
          this.current_bonus = 0;
          this.current_credit = 0;
          this.current_credit_simple = 0;
        }
      },
      phoneMask(v) {
        let r = v.replace(/\D/g, "");
        r = r.replace(/^0/, "");
        if (r.length > 11) {
          r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (r.length > 7) {
          r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
        } else if (r.length > 2) {
          r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } else if (v.trim() !== "") {
          r = r.replace(/^(\d*)/, "($1");
        }
        return r;
      },
      countTotalHunches(hunches) {
        let selectedList = [];
        for (let key in hunches) {
          let hun = hunches[key];
          if (!selectedList.includes(hun.id)) {
            selectedList.push(hun.id);
          }
        }
        return selectedList.length;
      }
    }
  })

  router.beforeEach((to, from, next) => {

    let ticketCode = '';

    const publicPages = ['/'];
    const listAllowedFront = ['1','4'];
    const listAllowedBack = ['2','3','5'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('x-token');
    const pipe = localStorage.getItem('firebase:pipe');

    if (!to.path.includes('ticket') && !to.path.includes('streaming') && !to.path.includes('prebet') && !to.path.includes('affiliate') && !to.path.includes('validate') && !to.path.includes('register') && !to.path.includes('login') && !to.path.includes('store') && !to.path.includes('cassino') && authRequired && !loggedIn) {
      return next({ 
        path: '/'
      });
    } 

    if (pipe && loggedIn) {
      
      if (to.path.includes('ticket')) {
        if (to.params.hasOwnProperty('code')) {
          ticketCode = String(to.params.code).toLowerCase();
        }
      }

      if (to.path.includes('dashboard') && listAllowedFront.includes(pipe.toString())) {
        return next({ 
          path: ticketCode ? `/ticket=${ticketCode}` : '/'
        });
      } else if (!to.path.includes('dashboard') && listAllowedBack.includes(pipe.toString())) {
        return next({ 
          path: ticketCode ? `/dashboard/bets?ticket=${ticketCode}` : '/dashboard'
        });
      }
    }

    next();
  })

  new Vue({ 
    router, 
    render: h => h(Home),
    components: {
      Home
    },
    data() {
      return {
        body: document.body,
        listAllowed: ['2', '3', '5']
      }
    },
    methods: {
      setItem(key, value) {
        return Promise.resolve().then(() => {
            window.localStorage.setItem(key, value);
        });
      },
      removeItem(key) {
        return Promise.resolve().then(() => {
            window.localStorage.removeItem(key);
        });
      },
      setGeolocation(data) {
        this.geolocation.ip = data.query;
        this.geolocation.pais = data.country;
        this.geolocation.cidade = data.city;
        this.geolocation.estado = data.regionName;
        this.geolocation.latitude = data.lat;
        this.geolocation.longitude = data.lon;
        this.geolocation.provedor = data.isp || data.org;
      }
    },
    watch: {
      '$route': {
        immediate: true,
        handler(to) {
          try {
            if (this.$route.params.hasOwnProperty('code')) {
              document.title = `${to.meta.title} - ${String(this.$route.params.code).toUpperCase()}`;
            } else {
              document.title = to.meta.title || pageTitle;
            } 
          } catch(e) {
            document.title = pageTitle;
          } finally {
            this.sidebar = false;
            this.htmlElement(to.path);
            this.isLivePageActive = false;
          }
        }
      },
      user_id(value) {
        if (value) {
          this.getBalanceUser();
        }
      }
    },
    beforeCreate() {
      try {
        DisableDevtool({
          ignore: ['localhost'],
          url: 'https://betsnow.net',
          disableMenu: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? false : true
        });
      } catch(e) {
        window.sessionStorage.setItem('error-before-create', e.message);
      }
    },
    beforeMount() {
      try {
        axios.get('https://api.betsnow.net/geolocation').then((response) => {
          if (response.data.status == 'success') {
            this.setGeolocation(response.data);
          }
        });
      } catch(e) {
        // window.console.log(e.message);
      }
    },
    mounted() {
        // Block IOS Zoom page
        document.addEventListener('gesturestart', (e) => {
            e.preventDefault();
        });

        document.addEventListener('DOMContentLoaded', () => {

          var page_loading = document.querySelector('.page-loading');
              page_loading.style.display = 'none';

          setTimeout(() => {
            if (!page_loading.getAttribute('style')) {
              page_loading.innerHTML = `
                <div class="d-flex flex-column text-center">
                    <div class="display-2 text-warning">
                      <i class="fab fa-connectdevelop"></i>
                    </div>
                    <p class="text-white">O site demorou para abrir?</p>
                    <button class="btn btn-secondary my-2 my-sm-0" onclick="window.location.reload();">Clique aqui para recarregar</button>
                </div>
              `;
            }
          }, 20000);
        }, false);
    },
    created() {

      const token = window.localStorage.getItem('x-token') || undefined;

      if (typeof token !== 'undefined') {

        api.get('auth', {
          timeout: 10000,
          headers: {
            'X-Access-Token': token
          }
        }).then((response) => {
          this.setItem('auth', JSON.stringify(response.data)).then(() => {
            this.setItem('firebase:pipe', response.data.pipe).then(() => {
              this.user_id = response.data.id;
            });
          });
        }).catch(() => {
          this.removeItem('auth').then(() => {
            this.removeItem('x-token').then(() => {
              this.removeItem('firebase:pipe').then(() => {
                window.location.href = '/';
              });
            });
          });
        });
      } 
      else 
      {
        this.removeItem('auth');
      }
    }
  }).$mount('#app')
</script>
